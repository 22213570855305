<div *ngIf="user" class="profile-wrapper">
  <mat-card appearance="outlined" class="profile-form-card">
    <mat-card-content class="profile-form-wrapper">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput [disabled]="userCreated" [(ngModel)]="user.firstName" id="firstName" name="firstName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput [disabled]="userCreated" [(ngModel)]="user.lastName" id="lastName" name="lastName" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput [disabled]="userCreated" [(ngModel)]="user.title" id="title" length="120" name="Title" />
      </mat-form-field>
      <div class="mat-form-field-radio-group">
        <mat-label>Work Status</mat-label>
        <mat-radio-group matInput aria-label="Work Status" [(ngModel)]="workStatus">
          <mat-radio-button *ngFor="let key of WORK_STATUS_TYPE_KEYS" value="{{key}}">{{WORK_STATUS_TYPE_LABELS[key]}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="mat-form-field-radio-group">
        <mat-label>Willing to Relocate?</mat-label>
        <mat-radio-group matInput aria-label="Willing to Relocate?" [(ngModel)]="relocate">
          <mat-radio-button value="yes">Yes</mat-radio-button>
          <mat-radio-button value="no">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-form-field class="full-width">
        <mat-label>About Me</mat-label>
        <textarea matInput [(ngModel)]="userBiography" id="bio" maxlength="500" name="Biography" rows="6"></textarea>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="updateProfile()" mat-raised-button type="submit" color="accent">Update</button>
      <button (click)="openChangePassword()" mat-button type="submit">Change Password</button>
      <button (click)="openAuthorizeCalendarAccess()" *ngIf="user.id" mat-button type="submit">Authorize Calendar
        Access
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-card appearance="outlined" class="profile-image-card">
    <mat-card-content>
      <mat-card-title>Profile Photo</mat-card-title>
      <!-- Display Image -->
      <inthraction-profile-image *ngIf="showPhoto" [employeeImage]="userImageData"></inthraction-profile-image>
      <!-- Photo Picker -->
      <input *ngIf="!showPhoto"
             type="file"
             id="imageUpload"
             name="imageUpload"
             accept="image/png, image/jpeg"
             (change)="imageSelected($event)"
      />
    </mat-card-content>
    <mat-card-actions>
      <button (click)="editPhoto()" *ngIf="showPhoto" mat-button>Edit Photo</button>
      <button (click)="cancelEditPhoto()" *ngIf="!showPhoto" mat-button>Cancel</button>
      <button *ngIf="!showPhoto" (click)="uploadImage()" mat-button type="button" [disabled]="!selectedFile" color="accent">
        <mat-icon>cloud_upload</mat-icon>
        Upload
      </button>
    </mat-card-actions>
  </mat-card>
  <mat-card appearance="outlined" style="width: 24em;">
    <mat-card-header>
      <mat-card-title>Preferences</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Show Help Bubbles</mat-label>
        <mat-slide-toggle (change)="showHelpDisabled=!$event.checked" [checked]="!showHelpDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-form-field *ngIf="!intHRactionDisabled" floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Disable Email notification after every intHRaction event</mat-label>
        <mat-slide-toggle (change)="eventEmailDisabled=$event.checked" [checked]="eventEmailDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-form-field *ngIf="!intHRactionDisabled" floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Disable Daily intHRaction summary email</mat-label>
        <mat-slide-toggle (change)="summaryEmailDisabled=$event.checked" [checked]="summaryEmailDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Disable inspHRation emails</mat-label>
        <mat-slide-toggle (change)="insphrationEmailDisabled=$event.checked" [checked]="insphrationEmailDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Disable Stand-Up emails</mat-label>
        <mat-slide-toggle (change)="standupEmailDisabled=$event.checked" [checked]="standupEmailDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
      <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
        <mat-label>Disable Objective emails</mat-label>
        <mat-slide-toggle (change)="objectiveEmailDisabled=$event.checked" [checked]="objectiveEmailDisabled"></mat-slide-toggle>
        <input matInput hidden height="0" style="height: 0" />
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button #notificationPreferenceSubmitBtn mat-raised-button (click)="saveNotificationPreferences(notificationPreferenceSubmitBtn)" type="submit" color="accent">Save</button>
      <button mat-button (click)="resetNotificationPreferences()">Cancel</button>
    </mat-card-actions>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Career Aspirations</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <inthraction-career-aspirations [employeeID]="user.id" [managerAccess]="false"></inthraction-career-aspirations>
    </mat-card-content>
  </mat-card>
</div>
