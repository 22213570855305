import { Component, NgZone, OnInit } from "@angular/core";
import { Employee, EmployeeData, EmployeeImageInterface, EmployeeImpl, Organization } from "@inthraction/data-models";
import { AuthService, EmployeeService, OrganizationService } from "@inthraction/services";
import { MatDialog } from "@angular/material/dialog";
import { ChangePasswordComponent } from "./change-password.component";
import {
  AuthorizeCalendarAccessComponent
} from "../../components/authorize-calendar-access/authorize-calendar-access.component";
import { HeaderComponent } from "../../components/shared/layout/header/header.component";
import { ToastrService } from "ngx-toastr";
import { EmployeeDataTypes, OrganizationConfigurationCodes, SiteCodes } from "@inthraction/codes";
import { EmployeePreferences } from "@inthraction/data-mappers";
import { MatButton } from "@angular/material/button";
import { WORK_STATUS_TYPE_LABELS } from "@inthraction/labels";
import { Storage } from "aws-amplify";
import { StoragePutOutput } from "@aws-amplify/storage/src/types";


@Component({
  selector: "inthraction-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {

  readonly WORK_STATUS_TYPE_LABELS = WORK_STATUS_TYPE_LABELS;
  readonly WORK_STATUS_TYPE_KEYS = Object.keys(WORK_STATUS_TYPE_LABELS);
  showPhoto: boolean;
  userCreated: boolean;

  authUserId: string;
  userId: string;
  userDomain: string;
  userEmail: string;
  userOrg: Organization;
  user: Employee;
  userImageData: EmployeeImageInterface;
  userBiography: string;
  relocate: string;
  workStatus: string;

  eventEmailDisabled: boolean;
  summaryEmailDisabled: boolean;
  insphrationEmailDisabled: boolean;
  standupEmailDisabled: boolean;
  objectiveEmailDisabled: boolean;
  objectiveEmailSummary: boolean;
  showHelpDisabled: boolean;
  intHRactionDisabled:boolean;

  private userPreferences: EmployeeData;
  private employeeDataRecords: EmployeeData[] = [];
  selectedFile: File;

  constructor(
    public dialog: MatDialog,
    private employeeService: EmployeeService,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private authService: AuthService
  ) {
  }

  async ngOnInit() {
    this.showPhoto = false;
    const employee = await this.employeeService.getCurrentEmployee();
    if (employee) {
      const credentials = await this.authService.currentCredentials();
      this.authUserId = credentials.identityId;
      this.userEmail = employee.email;
      this.userDomain = this.userEmail.split("@")[1].toLowerCase();
      let result: Employee;
      try {
        const employee = await this.employeeService.getEmployeeByEmailMemoize(this.userEmail);
        if (employee) {
          result = employee;
          this.userImageData = await this.employeeService.getEmployeeImageMemoize(employee.id, employee.orgId);
        }
      } catch (e) {
        console.error(e);
      }

      if (!result) {
        this.userCreated = false;
        this.userId = null;
        const matchedOrg = await this.organizationService.getOrganizationsByDomain(this.userDomain);
        if (matchedOrg.length > 0) {
          this.userOrg = matchedOrg[0];
        } else { // No org exists... create a new one.
          const newOrg = new Organization();
          newOrg.domains = [this.userDomain];
          newOrg.orgName = this.userDomain;
          //TODO Pick the correct site
          newOrg.site = SiteCodes.INTHRACTION;
          this.userOrg = await this.organizationService.createOrganization(newOrg);
        }

        this.user = new EmployeeImpl({
          authId: null,
          disabled: false,
          disabledBy: null,
          disabledDate: null,
          email: employee.email,
          firstName: null,
          hireDate: null,
          department: null,
          id: null,
          lastAnnual: null,
          lastName: null,
          lastOneOnOne: null,
          orgId: this.userOrg.id,
          positionDate: null,
          previousPositionStartDate: null,
          previousPositionTitle: null,
          title: null
        });
      } else {
        this.userOrg = await this.organizationService.getOrganizationByIDMemoize(result.orgId);
        this.userId = result.id;
        this.userCreated = true;
        this.user = result;

        this.userImageData = await this.employeeService.getEmployeeImageMemoize(this.user.id, this.user.orgId);
        this.showPhoto = (this.userImageData?.image && !!this.userImageData.image.trim());

        this.employeeDataRecords = await this.employeeService.getEmployeeData(this.user.orgId, this.userId, [EmployeeDataTypes.RELOCATABLE, EmployeeDataTypes.WORK_STATUS, EmployeeDataTypes.BIOGRAPHY], true);
        for (const employeeDataRecord of this.employeeDataRecords) {
          if (employeeDataRecord.dataCode === EmployeeDataTypes.RELOCATABLE) {
            this.relocate = employeeDataRecord.booleanValue ? "yes" : "no";
          } else if (employeeDataRecord.dataCode === EmployeeDataTypes.WORK_STATUS) {
            this.workStatus = employeeDataRecord.stringValue;
          } else if (employeeDataRecord.dataCode === EmployeeDataTypes.BIOGRAPHY) {
            this.userBiography = employeeDataRecord.stringValue;
          }
        }

        if (!result.validEmailToken) {
          this.openAuthorizeCalendarAccess();
        }
      }

      const preferences = await this.employeeService.getEmployeeData(this.user.orgId, this.user.id, [EmployeeDataTypes.PREFERENCES], true);
      if (preferences && preferences.length) {
        this.userPreferences = preferences[0];
      }
      this.initializePreferences(this.userPreferences);

      const orgIntHRactionSurveyDisabledConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.EVENT_SURVEY_DISABLED);
      this.intHRactionDisabled = orgIntHRactionSurveyDisabledConfiguration.configBooleanValue;

    }
  }

  imageSelected = (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (!input.files?.length) {
      return;
    }
    this.selectedFile = input.files[0];
  };

  uploadImage = async () => {
    if (!this.selectedFile) {
      return;
    }
    try {
      const filteredName = this.selectedFile.name.replace(/\s/g, "_").replace(/[^a-zA-Z0-9_.]+/g, "");
      const uploadedPhoto = await Storage.put(`image/${filteredName}`, this.selectedFile, {
        contentType: "image/*",
        level: "protected"
      });

      await this.onImageUploaded(uploadedPhoto);

    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  private initializePreferences(userPreferences: EmployeeData) {
    if (userPreferences) {
      try {
        const employeePreferences: EmployeePreferences = JSON.parse(userPreferences.stringValue);
        this.eventEmailDisabled = employeePreferences.eventEmailDisabled;
        this.summaryEmailDisabled = employeePreferences.summaryEmailDisabled;
        this.insphrationEmailDisabled = employeePreferences.insphrationEmailDisabled;
        this.standupEmailDisabled = employeePreferences.standupEmailDisabled;
        this.showHelpDisabled = employeePreferences.disableShowHelpIcons;
        this.objectiveEmailDisabled = employeePreferences.objectiveEmailDisabled;
        this.objectiveEmailSummary = employeePreferences.objectiveEmailSummary;
      } catch (err) {
        console.error("Unable to parse User Preferences", err);
      }
    } else {
      this.eventEmailDisabled = false;
      this.summaryEmailDisabled = false;
      this.insphrationEmailDisabled = false;
      this.standupEmailDisabled = false;
      this.showHelpDisabled = false;
      this.objectiveEmailDisabled = false;
      this.objectiveEmailSummary = false;
    }
  }

  openChangePassword(): void {
    this.dialog.open(ChangePasswordComponent, {
      width: "600px",
      data: {}
    });
  }

  openAuthorizeCalendarAccess(): void {
    this.dialog.open(AuthorizeCalendarAccessComponent, {
      width: "600px",
      data: {}
    });
  }

  async onImageUploaded(storagePutOutput: StoragePutOutput<any>): Promise<void> {
    if (this.userImageData) {
      this.userImageData.image = storagePutOutput.key;
      this.userImageData.imageIdentityId = this.authUserId;
    } else {
      this.userImageData = {
        image: storagePutOutput.key,
        employeeID: this.userId,
        organizationID: this.userOrg.id,
        imageIdentityId: this.authUserId
      };
    }

    if (this.userCreated) {
      this.userImageData = await this.employeeService.putEmployeeImage(this.userImageData);
    }
    setTimeout(() => this.ngZone.run(() => {
      this.showPhoto = true;
    }), 200);
  }

  editPhoto(): void {
    this.showPhoto = false;
  }

  cancelEditPhoto(): void {
    this.showPhoto = true;
  }

  async updateProfile(): Promise<void> {
    if (this.userCreated) {
      // const uEmployee: any = {
      //   id: this.userId,
      //   firstName: this.user.firstName,
      //   lastName: this.user.lastName,
      // };
      // await this.employeeService.updateEmployee(uEmployee);
    } else {
      const uEmployee: any = {
        id: this.userId,
        authId: this.authUserId,
        email: this.userEmail,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        title: this.user.title,
        orgId: this.userOrg.id,
        disabled: this.user.disabled,
        groups: [`HR-${this.userOrg.id}`, `ADMIN-${this.userOrg.id}`]
      };
      const newEmployee = await this.employeeService.createEmployee(uEmployee);
      this.userCreated = true;
      this.userOrg = await this.organizationService.getOrganizationByIDMemoize(newEmployee.orgId);
      this.userId = newEmployee.id;
      this.userCreated = true;
      this.user = newEmployee;
      this.employeeService.clearMemoizedEmployee(this.user);
      if (!newEmployee.validEmailToken) {
        this.openAuthorizeCalendarAccess();
      }
      HeaderComponent.initUserSubject.next(true);
    }

    // Update Employee Image
    if (this.userImageData) {
      this.userImageData.employeeID = this.user.id;
      this.userImageData.organizationID = this.userOrg.id;
      this.userImageData = await this.employeeService.putEmployeeImage(this.userImageData);
      this.showPhoto = (this.userImageData?.image && !!this.userImageData.image.trim());
    }

    // Update Employee Data
    let biographyRecord = this.getEmployeeDataRecord(EmployeeDataTypes.BIOGRAPHY);
    if (this.userBiography && this.userBiography !== biographyRecord?.stringValue) {
      if (!biographyRecord) {
        biographyRecord = {
          employeeID: this.userId,
          organizationID: this.userOrg.id,
          dataCode: EmployeeDataTypes.BIOGRAPHY,
          stringValue: this.userBiography.trim()
        };
      } else {
        biographyRecord.stringValue = this.userBiography.trim();
      }
      biographyRecord = await this.employeeService.putEmployeeData(biographyRecord);
      this.setEmployeeDataRecord(biographyRecord);
    }

    let relocatableRecord = this.getEmployeeDataRecord(EmployeeDataTypes.RELOCATABLE);
    if (this.relocate && (this.relocate === "yes") !== relocatableRecord?.booleanValue) {
      if (!relocatableRecord) {
        relocatableRecord = {
          employeeID: this.userId,
          organizationID: this.userOrg.id,
          dataCode: EmployeeDataTypes.RELOCATABLE,
          booleanValue: this.relocate === "yes"
        };
      } else {
        relocatableRecord.booleanValue = this.relocate === "yes";
      }
      relocatableRecord = await this.employeeService.putEmployeeData(relocatableRecord);
      this.setEmployeeDataRecord(relocatableRecord);
    }

    let workStatusRecord = this.getEmployeeDataRecord(EmployeeDataTypes.WORK_STATUS);
    if (this.workStatus && this.workStatus !== workStatusRecord?.stringValue) {
      if (!workStatusRecord) {
        workStatusRecord = {
          employeeID: this.userId,
          organizationID: this.userOrg.id,
          dataCode: EmployeeDataTypes.WORK_STATUS,
          stringValue: this.workStatus.trim()
        };
      } else {
        workStatusRecord.stringValue = this.workStatus.trim();
      }
      workStatusRecord = await this.employeeService.putEmployeeData(workStatusRecord);
      this.setEmployeeDataRecord(workStatusRecord);
    }

    this.toastr.success("Profile Updated");
  }

  resetNotificationPreferences() {
    this.initializePreferences(this.userPreferences);
  }

  async saveNotificationPreferences(submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;
    if (!this.userPreferences) {
      this.userPreferences = {
        employeeID: this.user.id,
        organizationID: this.user.orgId,
        dataCode: EmployeeDataTypes.PREFERENCES
      };
    }
    const preferences: EmployeePreferences = {
      summaryEmailDisabled: this.summaryEmailDisabled,
      eventEmailDisabled: this.eventEmailDisabled,
      insphrationEmailDisabled: this.insphrationEmailDisabled,
      standupEmailDisabled: this.standupEmailDisabled,
      disableShowHelpIcons: this.showHelpDisabled,
      objectiveEmailDisabled: this.objectiveEmailDisabled,
      objectiveEmailSummary: this.objectiveEmailSummary
    };
    this.userPreferences.stringValue = JSON.stringify(preferences);
    const result = await this.employeeService.putEmployeeData(this.userPreferences);
    if (result) {
      this.toastr.success("Preferences Saved");
      this.userPreferences = result;
    } else {
      this.toastr.error("Unable to update Preferences");
    }
    submitBtn.disabled = false;
  }

  private getEmployeeDataRecord(type: EmployeeDataTypes) {
    const records = this.employeeDataRecords.filter(x => x.dataCode === type);
    if (records && records.length > 0) {
      return records[0];
    } else {
      return null;
    }
  }

  private setEmployeeDataRecord(record: EmployeeData) {
    const records = this.employeeDataRecords.filter(x => x.dataCode !== record.dataCode);
    records.push(record);
    this.employeeDataRecords = records;
  }
}
